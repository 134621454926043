/* tslint:disable */
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng';
import { environment } from 'src/environments/environment';
import { TransactionService } from '../loginpage/Transaction.service';
import { ActivePlants, ApprovalStatus, archiveSearchPartExcelFormat, fromdiplayvalues_all, GPP_search_grid_API, GPP_Search_Prt_grid, myUserAccessRoles, partsbulkemailpost, programsbasedonplants, programsbasedonplantsforsearchprop, RevHistFromAPi, RevisionObj, searchPartExcelFormat, searchpartgrid, searchpartgridfromapi, searchProposalGrid, searchProposalGridFromAPI, selec_prt_pro_details, substatuslist, subtradco, supplierForSearchPart, supplierforsearchproposal, supp_country } from '../loginpage/transactions';

@Component({
  selector: 'app-search-parts',
  templateUrl: './search-parts.component.html',
  styleUrls: ['./search-parts.component.css'],
  providers:[MessageService]
})
export class SearchPartsComponent implements OnInit {


  current_region:string;
  region_code:string;
  userid:string;

  isloadersearchprop:boolean=false;
  isloaderArchiveSearchPart:boolean=false;

  userIsFord:boolean=false;

  part_prefix:string="";
  part_base:string="";
  part_suffix:string="";
  part_desc:string="";
  selectedpartprefix:string="";
  selectedpartbase:string="";
  selectedpartsuffix:string="";
  selectedpartdesc:string="";

  selected_plant_code:ActivePlants={
    plant_name: "",
    cd_plant: "",
    plant_type_name: "",
    plantGSDBCode: "",
  
    
  
  }
  plantcode:string="";
  selected_plant_codes_list:ActivePlants[]=[];
  plantcodelist:ActivePlants[]=[];
  all_selected_on_plant_code=false;
  plantcodeselected=false;


  programsList:programsbasedonplants[]=[];
  programListalongwithPlants:programsbasedonplantsforsearchprop[]=[];
  selected_programs_list:programsbasedonplantsforsearchprop[]=[];
  programcodes='';
  all_selected_on_program=false;

  regionlist:any=[];
  selected_region_from_list: any[]=[];

  part_sup_code='';
  part_sup_name='';
  apprv_stat_grid_inp='';
  part_trad_cmp='';
  part_status_grid_inp='';
  part_sp_cntry='';

  part_plant_code='';
  part_prg_code='';


  supplierGSDBcode='';
  supplierfullname='';

  supplierList:supplierForSearchPart[]=[];
  selected_supplier:supplierForSearchPart={supcode:'',fullname:'',supplierid:''};
  suupliercode:string="";
  supplierEntered:string="";
  fordPersonLoggedIn:boolean=true;
 
  suppliercountryList:supp_country[]=[];
  selected_suppliercountry:supp_country[]=[];
  suppliercountry='';
  all_selected_supp_cntry=false;

  tradCmpyList:subtradco[]=[];
  selectedtrdcmpy:subtradco[]=[];
  all_selected_trad_comp=false;
  trdcmpy='';


  apprvr_status_list:ApprovalStatus[];
  selected_approval_status:ApprovalStatus[]=[];
  approval_status='';
  all_selected_in_approval_status=false;


  statusList:substatuslist[]=[];
  selectedstatuslist:substatuslist[]=[];
  part_status='';
  all_selected_part_status=false;

  searchpartgrid:searchpartgrid[]=[];
  searchpartgridfromapi:searchpartgridfromapi[]=[];
  cols:any;

  archiveSearchPartGrid:GPP_search_grid_API[]=[];
  archiveSearchPartFromAPI:GPP_search_grid_API[]=[];
  cols2:any;
  
  view_proposal=false;
  search_result='';
  archive_search_result="";

  viewOnlyForSupplier:string="-";
  userAccessGrid:myUserAccessRoles[]=[];

  revHistFromServiceAPI:RevHistFromAPi[]=[];

  proposal_details_search:fromdiplayvalues_all;
  revision_obj_search:RevisionObj[]=[];

  enabledetails_search=false;

  selectedpartnodetails:selec_prt_pro_details={simplifiedpartno:'-',bompartmasterid:0,proposalid:0,plantgsdbcode:'',proposallastsaved:0,part_status:'',supplier_code:'',proposallastsaved_ee:0,currentview_ee:false,is_ee_proposal:false,from_draft:false,propsl_type:'',imp_part_status:''};

  selectedPartsForBulkEmail:searchpartgrid[]=[];

  enableSendEmailPopUp=false;
  bulkEmailSubject='';
  bulkEmailBody=''
  copyMeOnBulkEmail=false;

  selectedPartsForBulkEmailPost:partsbulkemailpost={

    partDetls:[
      {
        partNumberCommonized:'',
        plantCode:'',
        programName:'',
        supCode:''
      }
    ],
    subject:'',
    emailBody:'',
    copyMe:'',
    user:''

  }

  enableViewSelectedPartsPopup=false;

  cols_view:any;

  enableBulkMailOptionOnUserAccess=false;

  errorLoadingData:string="";
  errorLoadingArchiveData:string="";

  enable=false;
  formlink:string="";
  historylink:string="";

  loadProposalText:string="";
  formId:string="";
  disableDownloadPdfButton:boolean=false;
  disableDownloadHistoryButton:boolean=false;
  isLoaderLoadFormArchive:boolean=false;
  isLoaderLoadHistoryArchive:boolean=false;
  downloadPdfText:string="";
  loadFormPDFerrorText:string="";
  loadHistoryPDFerrorText:string="";

  base_url_get_archieve_report:string=""; 
  base_path_archive_report:string=""; 

  prevFormId:string="";


  constructor(private router:Router,private transactionService:TransactionService,private messageService:MessageService,private http: HttpClient) { }


  ngOnInit(): void {
    this.current_region=this.transactionService.getregion();
    this.region_code=this.transactionService.getregioncode(this.current_region);
    this.userid=this.transactionService.getcdsid();

    this.viewOnlyForSupplier="-";
    this.loadProposalText="";

    this.base_url_get_archieve_report = environment.nfsBaseAPIUrl+"/downloadNonImgFile?fPath=";
    //this.base_path_archive_report = environment.nfsBasePath+"gpparchive/";
    this.base_path_archive_report = "gpparchive/";

    let userType=JSON.parse(sessionStorage.getItem("userType"));

    if(userType!=null && userType.toLowerCase()=="no")
    {
      this.fordPersonLoggedIn=false;
    }
    else
    {
      this.fordPersonLoggedIn=true;
    }
    
    this.loadRegionDropDown();
    this.loadsupplierdetails();
    this.loadtradingcompany('all');
    this.loadPartStatus();
    this.loadSupplierCountry();
    this.loadProposalStatus();
    this.loadPlantCode();



  }


  loadRegionDropDown()
  {
    var reg_lst=this.transactionService.getuserregion();
    for(var i=0;i<reg_lst.length;i++)
    {
      var regcode=reg_lst[i].toString();
      let regname=this.transactionService.getregionnamebycode(regcode);
      this.regionlist.push({label:regname,value:regname});
    }

    if(this.regionlist.length!=0)
    {
      if(this.regionlist.filter(ep=>ep.value==this.current_region))
      {
        this.selected_region_from_list.push({label:this.current_region, value: this.current_region});
      }

      // alert(this.selected_region_from_list);
    }

    
    var roles=this.transactionService.getroles(this.transactionService.getregioncode(this.current_region));
    let isFordUser=JSON.parse(sessionStorage.getItem('userType'));

    console.log(roles)

    if(isFordUser != null && isFordUser.toLowerCase() == 'no')
    {
      this.enableBulkMailOptionOnUserAccess=false;
    }
    else if(isFordUser != null && isFordUser.toLowerCase() == 'yes')
    {
      if(roles.length != 0 && roles.length == 1 && roles[0].roleid == 99)
      {
        this.enableBulkMailOptionOnUserAccess=false;
      }
      else
      {
        this.enableBulkMailOptionOnUserAccess=true;
      }
    }

    console.log(this.enableBulkMailOptionOnUserAccess)
    this.enableBulkMailOptionOnUserAccess=false;
  }

  loadsupplierdetails()
  {
      var regcode=this.transactionService.getregioncode(this.current_region);
      this.transactionService.getSupplierListForSearchPart(this.userid,regcode).subscribe(suplis=>{
        console.log('suppliers',suplis,this.region_code);
        this.supplierList=suplis;

        if(this.supplierList!=null)
        {
          for(const supplier of this.supplierList)
          {
            supplier.supplierid=supplier.supcode+' - '+supplier.fullname;
          }
        }
      })

  }

  loadtradingcompany(input:any)
  {
   // this.transactionService.gettradingcompany().subscribe(sub_tradcmpy=>{
    this.transactionService.getTradingCompaniesBasedOnPlantGSDBCode(input).subscribe(sub_tradcmpy=>{
      console.log(sub_tradcmpy);
      this.tradCmpyList=[];
      this.tradCmpyList.push({tradingcogsdbcode:'All'});
      this.tradCmpyList.push({tradingcogsdbcode:'[No Trading Company]'})
      for(let trd=0;trd<sub_tradcmpy.length;trd++)
      {
      this.tradCmpyList.push(sub_tradcmpy[trd]);
      }
      console.log('trad',this.tradCmpyList)
      this.selectedtrdcmpy=[];

    });
  }

  loadPartStatus()
  {
    this.transactionService.getpartstatus('searchpart').subscribe(sub_status=>{
      console.log(sub_status);
      this.statusList=[];
      this.statusList.push({status_id:0,status_desc:'all',status:'All',functions:'searchepro'});
      for(let i=0;i<sub_status.length;i++)
      {
        // if(sub_status[i].status!="Submitted")
        // {
        this.statusList.push(sub_status[i]);
        // }
      }
      this.selectedstatuslist=[];
    });
  }

  loadSupplierCountry()
  {
    this.transactionService.getsupplier_country_list().subscribe(supp_country_list=>{

      const resultsuppcntry=supp_country_list;
      const suppcntrytemp:supp_country[]=[];
      // this.suppliercountryList=supp_country_list;
      if(resultsuppcntry!=null && resultsuppcntry.length!=0)
      {
        suppcntrytemp.push({cd_country:'all',cd_country_iso:'all',country_name:'all',code_name:'ALL'})

        for(let s=0;s<resultsuppcntry.length;s++)
        {
          suppcntrytemp[s+1]=resultsuppcntry[s];
          if(resultsuppcntry[s].cd_country_iso!=null)
          {
            suppcntrytemp[s+1].code_name=resultsuppcntry[s].cd_country_iso+'-'+resultsuppcntry[s].country_name
          }
          else
          {
            suppcntrytemp[s+1].code_name=resultsuppcntry[s].country_name;
          }
        }

        this.selected_suppliercountry=[];
        this.suppliercountryList=suppcntrytemp;
        console.log('countrylist',this.suppliercountryList);

      }
    });
  }

  loadProposalStatus()
  {

    this.transactionService.getapprovalstatuslist().subscribe(apprstat=>{
      // this.apprvr_status_list=apprstat;
      const result:ApprovalStatus[]=apprstat;
      const apprtemp:ApprovalStatus[]=[];


        apprtemp.push({cd_propsl:'',propsl_stat:'All',propsl_next_stat:'',propsl_stat_desc:''})
        for(let p=0;p<result.length;p++)
        {
          if(result[p].cd_propsl!='1' && result[p].cd_propsl!='2' && result[p].cd_propsl!='12' && result[p].cd_propsl!='18' )
          {
            apprtemp.push({cd_propsl:result[p].cd_propsl,propsl_stat:result[p].propsl_stat,propsl_next_stat:result[p].propsl_next_stat,propsl_stat_desc:result[p].propsl_next_stat});
          }
        }
        this.apprvr_status_list=JSON.parse(JSON.stringify(apprtemp));
        console.log("approval status",this.apprvr_status_list)
        this.selected_approval_status=[];

    });
  }

  loadPlantCode()
  {
    var regforinput=this.transactionService.getAllRegionCode(this.selected_region_from_list);
          this.transactionService.getactiveplantslistByRegionList(regforinput).subscribe(plantco=>{
            
            let resultplant:ActivePlants[]=plantco;
            let plantcodetemp:ActivePlants[]=[];

            plantcodetemp.push({cd_plant:'0',plantGSDBCode:'ALL',plant_name:'All',plant_type_name:'All'});
            for(let h=0;h<resultplant.length;h++)
            {
              plantcodetemp.push({cd_plant:resultplant[h].cd_plant,plantGSDBCode:resultplant[h].plantGSDBCode,plant_name:resultplant[h].plant_name,plant_type_name:resultplant[h].plant_type_name})
            }
            this.plantcodelist=[];
            this.plantcodelist=plantcodetemp
            this.selected_plant_codes_list=[];
            this.selected_plant_code={
              plant_name: "",
              cd_plant: "",
              plant_type_name: "",
              plantGSDBCode: "",
            
              
            
            }
            console.log("plantcodes from backend",this.plantcodelist);
          })
  }

  /*change_plant_codes()
  {
    let all_selected_on_plant_code=false;
    let lenlessthan1_plant_code=false;
    this.plantcode='';

    // When plants is changed programs variable shd be emptied and
    // when click outside after selecting plants,these variables will have loaded data
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes='';
    this.programListalongwithPlants=[];


    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      lenlessthan1_plant_code=true;
    }
    for(let j=0;j<this.selected_plant_codes_list.length;j++)
    {
      if(this.selected_plant_codes_list[j].plantGSDBCode=='ALL')
      {
        all_selected_on_plant_code=true;
      }
    }

    if(all_selected_on_plant_code==false && lenlessthan1_plant_code==true)
    {
      all_selected_on_plant_code=true;
    }

    if(all_selected_on_plant_code==true)
    {
      this.plantcode='all';
      this.selected_plant_codes_list=[];
      this.selected_plant_codes_list.push({cd_plant:'0',plantGSDBCode:'ALL',plant_name:'All',plant_type_name:'All'});
    }
  }*/

  /*onhide_plant_codes()
  {
    this.all_selected_on_plant_code=false;
    this.plantcode='';

    // checking whether length of selected list is less than one 
    var plantslen:boolean=false;
    if(this.selected_plant_codes_list.length==this.plantcodelist.length-1)
    {
      plantslen=true;
    }

    for(let ptc=0;ptc<this.selected_plant_codes_list.length;ptc++)
    {
      this.plantcode+=this.selected_plant_codes_list[ptc].plantGSDBCode;
      if(ptc < this.selected_plant_codes_list.length-1)
      {
        this.plantcode+=',';
      }

      if(this.selected_plant_codes_list[ptc].plantGSDBCode=='ALL')
      {
        this.all_selected_on_plant_code=true;
        this.plantcode='all';
        break;
      }
    }

    // If selectedvariables length is one less than total length but all is not selected
     //  which means except all remaining are selected so make 
      // all_selected_on_plant_code as true and make code  send all to backend 

    if(this.all_selected_on_plant_code==false && plantslen==true)
    {
      this.plantcode='all';
      this.all_selected_on_plant_code=true;
    }
    console.log('plant codes in Hide',this.plantcode,typeof(this.plantcode));

    if(this.plantcode.length>0)
    {
      this.plantcodeselected=true;
      this.loadprogramcodes();
      this.loadtradingcompany(this.plantcode);
      console.log('plantcode selected is true')
    }
    else
    {
      this.plantcodeselected=false;
      this.programsList=[];
      this.selected_programs_list=[];
      this.programListalongwithPlants=[];
      this.programcodes='';
      this.loadtradingcompany('all');
    }
  }*/

  plantCodeChanged()
  {
    this.plantcodeselected=true;
    this.programsList=[];
    this.selected_programs_list=[];
    this.programcodes="";
    this.programListalongwithPlants=[];
    
    this.plantcode=this.selected_plant_code.plantGSDBCode;
    this.loadprogramcodes();
    this.loadtradingcompany(this.plantcode)
  }

  loadprogramcodes()
  {
    const tempobj:programsbasedonplantsforsearchprop[]=[];

    /* Region multi select changes */
    // let regval = this.current_region;
    let plant_region;
    this.transactionService.getTestPackMailInstruction(this.plantcode).subscribe((response: any) => {
      plant_region = response.cd_region;
    });
    this.transactionService.getPrgmsBasedonPlant(this.plantcode).subscribe((progs: any)=>{
      let customPrgmsList = [];
 

      progs.forEach((ele: any) => {
        let obj = {
          programname: ele.program_name,
          cd_region: plant_region,
          plantgsdbcode: this.plantcode
        }
        customPrgmsList.push(obj);
      });
      this.programsList = customPrgmsList;
      if(this.programsList.length>0)
      {
        tempobj.push({cd_region:plant_region,plantgsdbcode:"",programname:"All",programnamewithplant:"All"});
      }
      for(let l=0;l<this.programsList.length;l++)
      {
        let programwasalreadypresent=false;
        if(tempobj.length!=0)
        {
          if(tempobj.some(o=>o.programname.toLowerCase() == this.programsList[l].programname.toLowerCase()))
          {
            const index=tempobj.findIndex(object=>{return object.programname.toLowerCase()==this.programsList[l].programname.toLowerCase()});
            const newvalue=tempobj[index].programnamewithplant.slice(0,tempobj[index].programnamewithplant.length-1) ;
            tempobj[index].programnamewithplant=newvalue+', '+this.programsList[l].plantgsdbcode+')';
            programwasalreadypresent=true;
          }
        }
        if(!programwasalreadypresent)
        {
          tempobj.push({programname:this.programsList[l].programname,programnamewithplant:this.programsList[l].programname+' ('+this.programsList[l].plantgsdbcode+')',plantgsdbcode:this.programsList[l].plantgsdbcode,cd_region:this.programsList[l].cd_region});
        }

      }
      this.programListalongwithPlants=JSON.parse(JSON.stringify(tempobj));
      
      console.log("programList",this.programsList)
      console.log("programListalongwithPlants",this.programListalongwithPlants);
    });
  }

  onhide_program_codes()
  {
    this.all_selected_on_program=false;
    this.programcodes='';

    /* checking whether length of selected list is less than one */
    let prglen=false;
    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      prglen=true;
    }

    for(let prgi=0;prgi<this.selected_programs_list.length;prgi++)
    {
      this.programcodes+=this.selected_programs_list[prgi].programname;
      if(prgi < this.selected_programs_list.length-1)
      {
        this.programcodes+=',';
      }

      if(this.selected_programs_list[prgi].programname=='All')
      {
        this.all_selected_on_program=true;
        this.programcodes='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining are selected so make
       all_selected_on_program as true and make code  send all to backend */

    if(this.all_selected_on_program==false && prglen==true)
    {
      this.programcodes='all';
      this.all_selected_on_program=true;
    }
    console.log('program codes in Hide',this.programcodes,typeof(this.programcodes));

  }

  change_prgm_codes()
  {
    let all_selected_on_program=false;
    let lenlessthan1_prg_code=false;
    this.programcodes='';

    if(this.selected_programs_list.length==this.programListalongwithPlants.length-1)
    {
      lenlessthan1_prg_code=true;
    }
    for(let j=0;j<this.selected_programs_list.length;j++)
    {
      if(this.selected_programs_list[j].programname=='All')
      {
        all_selected_on_program=true;
      }
    }

    if(all_selected_on_program==false && lenlessthan1_prg_code==true)
    {
      all_selected_on_program=true;
    }

    if(all_selected_on_program==true)
    {
      this.programcodes='all';
      this.selected_programs_list=[];
      let plant_region;
      this.transactionService.getTestPackMailInstruction(this.plantcode).subscribe((response: any) => {
        plant_region = response.cd_region;
        this.selected_programs_list.push({cd_region:plant_region,plantgsdbcode:"",programname:"All",programnamewithplant:"All"});
      });
    }
  }

  regionchanged()
  {
    //alert("New Region"+this.selected_region_from_list);
    var newregion=this.transactionService.getAllRegionCode(this.selected_region_from_list);
  

    // When region changes plant and program list shd be made empty

    this.transactionService.getactiveplantslistByRegionList(newregion).subscribe(newplants=>{
      let res_new_plants=newplants;
      let temp_new_plant:ActivePlants[]=[];
      //temp_new_plant.push({'cd_plant':'0','plantGSDBCode':'ALL','plant_name':'All','plant_type_name':'All'});
      
      for(var j of res_new_plants)
      {
        temp_new_plant.push({cd_plant:j.cd_plant,plantGSDBCode:j.plantGSDBCode,plant_name:j.plant_name,plant_type_name:j.plant_type_name});
      }
      this.plantcodelist=JSON.parse(JSON.stringify(temp_new_plant));
      this.selected_plant_codes_list=[];
      this.selected_plant_code={
        plant_name: "",
        cd_plant: "",
        plant_type_name: "",
        plantGSDBCode: "",
      }
      this.plantcode="";
      this.programListalongwithPlants=[];
      this.programsList=[];
      this.selected_programs_list=[];
      this.programcodes='';
      this.plantcodeselected=false;
      this.loadtradingcompany("all");
    });

    // when region changed suppliers list changes
    this.supplierfullname='';
    this.selected_supplier={supcode:'',fullname:'',supplierid:''};
    this.supplierEntered="";
    this.suupliercode="";
    this.loadsupplierdetails(); 
  }

  supplierchanged()
  {
    //alert(JSON.stringify(this.selected_supplier));
    if(this.fordPersonLoggedIn!=null && this.fordPersonLoggedIn==true)
    {
      // Ford user
      if(this.supplierEntered!=null && this.supplierEntered.trim()!='')
      {
        this.transactionService.getsuppdet(this.supplierEntered.toUpperCase(),"N").subscribe(supdet=>{
          if(supdet!=null)
          {
            this.supplierfullname=supdet.fullname;
            this.suupliercode=this.selected_supplier.supcode;
          }
          else
          {
            this.messageService.add({severity:'error', summary: 'Error', detail: 'Not a Valid Supplier GSDB Code'});
          }
          
        })
      }
      else{
        
        this.suupliercode="";
       this.supplierEntered="";
       this.supplierfullname="";
      
     }
    }
    
   else
   {
    this.transactionService.getsuppdet(this.selected_supplier.supcode,"N").subscribe(supdet=>{
      console.log("supdet",supdet)
      this.supplierfullname=supdet.fullname;
      this.suupliercode=this.selected_supplier.supcode;
    })

   }
        
  }

  onhidesuppcountry()
  {
    this.all_selected_supp_cntry=false;
    this.suppliercountry='';

    /* checking whether length of selected list is less than one */
    let suppcntrylen=false;
    if(this.selected_suppliercountry.length==this.suppliercountryList.length-1)
    {
      suppcntrylen=true;
    }

    for(let spct=0;spct<this.selected_suppliercountry.length;spct++)
    {
      this.suppliercountry+=this.selected_suppliercountry[spct].cd_country;
      if(spct < this.selected_suppliercountry.length-1)
      {
        this.suppliercountry+=',';
      }

      if(this.selected_suppliercountry[spct].code_name=='ALL')
      {
        this.all_selected_supp_cntry=true;
        this.suppliercountry='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_supp_cntry==false && suppcntrylen==true)
    {
      this.suppliercountry='all';
      this.all_selected_supp_cntry=true;
    }
    console.log('Supplier Country',this.suppliercountry,typeof(this.suppliercountry));

  }

  change_supp_cntry()
  {
    let all_present_in_supp_cntry=false;
    let lenlessthan1_supp_cntry=false;
    this.suppliercountry='';

    if(this.selected_suppliercountry.length==this.suppliercountryList.length-1)
    {
      lenlessthan1_supp_cntry=true;
    }
    for(let j=0;j<this.selected_suppliercountry.length;j++)
    {
      if(this.selected_suppliercountry[j].code_name=='ALL')
      {
        all_present_in_supp_cntry=true;
      }
    }

    if(all_present_in_supp_cntry==false && lenlessthan1_supp_cntry==true)
    {
      all_present_in_supp_cntry=true;
    }

    if(all_present_in_supp_cntry==true)
    {
      this.suppliercountry='all';
      this.selected_suppliercountry=[];
      this.selected_suppliercountry.push({cd_country:'all',cd_country_iso:'all',country_name:'all',code_name:'ALL'});
    }
  }


  onhidetradcomp()
  {
    this.all_selected_trad_comp=false;
    this.trdcmpy='';
    /* checking whether length of selected list is less than one */
    let tradcomplen=false;
    if(this.selectedtrdcmpy.length==this.tradCmpyList.length-1)
    {
      tradcomplen=true;
    }

    for(let trct=0;trct<this.selectedtrdcmpy.length;trct++)
    {
      if(this.selectedtrdcmpy[trct].tradingcogsdbcode=='[No Trading Company]')
      {
        this.trdcmpy+='notradco'
      }
      else
      {
        this.trdcmpy+=this.selectedtrdcmpy[trct].tradingcogsdbcode;
      }
      if (trct < this.selectedtrdcmpy.length-1)
      {
        this.trdcmpy+=',';
      }

      if(this.selectedtrdcmpy[trct].tradingcogsdbcode=='All')
      {
        this.all_selected_trad_comp=true;
        this.trdcmpy='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_trad_comp==false && tradcomplen==true)
    {
      this.trdcmpy='all';
      this.all_selected_trad_comp=true;
    }
    console.log('Trading Company',this.trdcmpy,typeof(this.trdcmpy));

  }

  chnagetradcomp()
  {
    let all_selected_trad_comp=false;
    let lenlessthan1_trad_cmp=false;
    this.trdcmpy='';

    if(this.selectedtrdcmpy.length==this.tradCmpyList.length-1)
    {
      lenlessthan1_trad_cmp=true;
    }
    for(let j=0;j<this.selectedtrdcmpy.length;j++)
    {
      if(this.selectedtrdcmpy[j].tradingcogsdbcode=='All')
      {
        all_selected_trad_comp=true;
      }
    }

    if(all_selected_trad_comp==false && lenlessthan1_trad_cmp==true)
    {
      all_selected_trad_comp=true;
    }

    if(all_selected_trad_comp==true)
    {
      this.trdcmpy='all';
      this.selectedtrdcmpy=[];
      this.selectedtrdcmpy.push({tradingcogsdbcode:'All'});
    }
  }


  onhide_apprvl_status()
  {
    this.all_selected_in_approval_status=false;
    this.approval_status='';

    /* checking whether length of selected list is less than one */
    let approval_stat_len=false;
    if(this.selected_approval_status.length==this.apprvr_status_list.length-1)
    {
      approval_stat_len=true;
    }

    for(let apst=0;apst<this.selected_approval_status.length;apst++)
    {
      this.approval_status+=this.selected_approval_status[apst].cd_propsl;
      if(apst < this.selected_approval_status.length-1)
      {
        this.approval_status+=',';
      }

      if(this.selected_approval_status[apst].cd_propsl=='')
      {
        this.all_selected_in_approval_status=true;
        this.approval_status='all';
        break;
      }
    }

    /* If selectedvariables length is one less than total length but all is not selected
       which means except all remaining (both expendable and returnable are selected) so make
       all_selected_in_ownrshp_catg as true and make code  send all to backend */

    if(this.all_selected_in_approval_status==false && approval_stat_len==true)
    {
      this.approval_status='all';
      this.all_selected_in_approval_status=true;
    }
    console.log('Approval Status',this.approval_status,typeof(this.approval_status));

  }

  change_apprvl_status()
  {
    let all_present_in_apprvr_stat=false;
    let lenlessthan1_apprvr_stat=false;
    this.approval_status='';

    if(this.selected_approval_status.length==this.apprvr_status_list.length-1)
    {
      lenlessthan1_apprvr_stat=true;
    }
    for(let j=0;j<this.selected_approval_status.length;j++)
    {
      if(this.selected_approval_status[j].cd_propsl=='')
      {
        all_present_in_apprvr_stat=true;
      }
    }

    if(all_present_in_apprvr_stat==false && lenlessthan1_apprvr_stat==true)
    {
      all_present_in_apprvr_stat=true;
    }

    if(all_present_in_apprvr_stat==true)
    {
      this.approval_status='all';
      this.selected_approval_status=[];
      this.selected_approval_status.push({cd_propsl:'',propsl_stat:'All',propsl_next_stat:'',propsl_stat_desc:''});
    }

  }

  
  // onhidepartstatus()
  // {
  //   this.all_selected_part_status=false;
  //   this.part_status="";
  //   /* checking whether length of selected list is less than one */
  //   var partstatuslen:boolean=false;
  //   if(this.selectedstatuslist.length==this.statusList.length-1)
  //   {
  //     partstatuslen=true;
  //   }

  //   for(var prtst=0;prtst<this.selectedstatuslist.length;prtst++)
  //   {
  //     this.part_status+=this.selectedstatuslist[prtst].status;
  //     if(prtst < this.selectedstatuslist.length-1)
  //     {
  //       this.part_status+=",";
  //     }

  //     if(this.selectedstatuslist[prtst].status=='All')
  //     {
  //       this.all_selected_part_status=true;
  //       this.part_status="all";
  //       break;
  //     }
  //   }

  //   /* If selectedvariables length is one less than total length but all is not selected
  //      which means except all remaining (both expendable and returnable are selected) so make 
  //      all_selected_in_ownrshp_catg as true and make code  send all to backend */

  //   if(this.all_selected_part_status==false && partstatuslen==true)
  //   {
  //     this.part_status="all";
  //     this.all_selected_part_status=true;
  //   }
  //   console.log("Part Status",this.part_status,typeof(this.part_status));
  
  // }

  // changepartstatus()
  // {
  //   var all_selected_part_status=false;
  //   var lenlessthan1_prt_status:boolean=false;
  //   this.part_status="";

  //   if(this.selectedstatuslist.length==this.statusList.length-1)
  //   {
  //     lenlessthan1_prt_status=true;
  //   }
  //   for(var j=0;j<this.selectedstatuslist.length;j++)
  //   {
  //     if(this.selectedstatuslist[j].status=='All')
  //     {
  //       all_selected_part_status=true;
  //     }
  //   }

  //   if(all_selected_part_status==false && lenlessthan1_prt_status==true)
  //   {
  //     all_selected_part_status=true;
  //   }

  //   if(all_selected_part_status==true)
  //   {
  //     this.part_status="all";
  //     this.selectedstatuslist=[];
  //     this.selectedstatuslist.push({status_id:0,status_desc:"all",status:"All",functions:"searchepro"});
  //   }
  // }

  partDescriptionRemoveSpecialCharecter()
  {
    this.selectedpartdesc=this.selectedpartdesc.replace(/[^a-zA-Z0-9-_ ]/g,'');
  }

  
  SupplierRemoveSpecialCharecter()
  {
    this.selectedpartdesc=this.selectedpartdesc.replace(/[^a-zA-Z0-9]/g,'');
  }


  search()
  {
    this.errorLoadingData="";
    this.errorLoadingArchiveData="";

    this.loadProposalText="";

    this.isloadersearchprop=true;
    this.isloaderArchiveSearchPart=true;

    this.search_result="";
    this.archive_search_result="";

    this.searchpartgrid=[];
    this.archiveSearchPartGrid=[];

    console.log(this.selectedpartprefix);
    console.log(this.selectedpartbase);
    console.log(this.selectedpartsuffix);

    if(this.selectedpartprefix!=null && this.selectedpartprefix!='')
    {
      this.part_prefix=this.selectedpartprefix

    }
    else{
      this.part_prefix='all'
    }

    if(this.selectedpartbase!=null && this.selectedpartbase!='')
    {
      this.part_base=this.selectedpartbase

    }
    else{
      this.part_base='all'
    }

    if(this.selectedpartsuffix!=null && this.selectedpartsuffix!='')
    {
      this.part_suffix=this.selectedpartsuffix

    }
    else{
      this.part_suffix='all'
    }

    if(this.selectedpartdesc!=null && this.selectedpartdesc!='')
    {
      this.part_desc=this.selectedpartdesc

    }
    else{
      this.part_desc='all'
    }

    // supplier code and name

    //if(this.suupliercode=="")
    if(this.fordPersonLoggedIn)
    {
      if(this.supplierEntered==null || (this.supplierEntered!=null && this.supplierEntered.trim()==""))
      {
        this.part_sup_code="all";
        this.part_sup_name="all";
      }
      else{
        this.part_sup_code=this.supplierEntered.toUpperCase();

        // setting this fullname to all because when user is not able to go out of focus after entering supplier gsdb code and directly gives search(), we will not have supplier name loaded at that time, so sending all
        if(this.supplierfullname==null || this.supplierfullname.trim()=='')
        {
          this.part_sup_name="all";
        }
        else
        {
          this.part_sup_name=this.supplierfullname;
        }
      }
    }
    else
    {
      if(this.suupliercode=="")
      {
        this.part_sup_code="all";
        this.part_sup_name="all";
      }
      else{
        this.part_sup_code=this.suupliercode;
        this.part_sup_name=this.supplierfullname;
      }
    }

    // Trading company

    if(this.trdcmpy=='')// || this.trdcmpy=="all" )
    {
      this.part_trad_cmp='all'
    }
    else
    {
      this.part_trad_cmp=this.trdcmpy;
    }

    // part status
    if(this.part_status=='')// || this.part_status=="all")
    {
      this.part_status_grid_inp='all';
    }
    else
    {
      this.part_status_grid_inp=this.part_status;
    }

    // Supplier country
    if(this.suppliercountry=='' )// || this.suppliercountry=="all")
    {
      this.part_sp_cntry='all';
    }
    else{
      this.part_sp_cntry=this.suppliercountry;
    }



    // approval status
    if(this.approval_status=='')// || this.approval_status=="all")
    {
      this.apprv_stat_grid_inp='all';
    }
    else{
      this.apprv_stat_grid_inp=this.approval_status;
    }


    // plant

    if(this.plantcode=='' )// || this.plantcode=="all")
    {
      this.part_plant_code='all';
    }
    else{
      this.part_plant_code=this.plantcode;
    }

    // programcode

    if(this.programcodes=='')// || this.programcodes=="all")
    {
      this.part_prg_code='all';
    }
    else{
      this.part_prg_code=this.programcodes;
    }



    //console.log("region in grid",this.transactionService.getregioncode(this.selected_region_from_list));

    console.log('prefix in grid',this.part_prefix);
    console.log('base in grid',this.part_base);
    console.log('suffix in grid',this.part_suffix);

    console.log('desc in grid',this.part_desc);

    console.log('supploercode in grid',this.part_sup_code);
    console.log('suuplier name in grid',this.part_sup_name);
    console.log('approval status in grid',this.apprv_stat_grid_inp);
    console.log('trading company in grid',this.part_trad_cmp);
    console.log('part status in grid',this.part_status_grid_inp);
    console.log('suppler country in grid',this.part_sp_cntry)

    console.log('plant code in grid',this.part_plant_code);
    console.log('program code in grid',this.part_prg_code);

    this.viewOnlyForSupplier="-";
    let isFordUser=JSON.parse(sessionStorage.getItem("userType"));
  
    if(isFordUser!=null && isFordUser.toLowerCase()=="no")
    {
      this.userIsFord=false;

      this.transactionService.getMyUserAccessRoles(this.transactionService.getcdsid()).subscribe(accessdetls=>{
        this.userAccessGrid=[];
        this.userAccessGrid=accessdetls;

        console.log(this.selected_region_from_list);
        let regionCode=this.transactionService.getregioncode(this.current_region);
        let index=this.userAccessGrid.findIndex(e=>e.cd_region.toLowerCase()==regionCode.toLowerCase() && e.role_id==99);

        if(index!=-1)
        {
          this.viewOnlyForSupplier=this.userAccessGrid[index].cd_supplier;
          this.searchPartGrid();
          //this.isloaderArchiveSearchPart=false; // no need to wait archive data
          this.loadArchiveSearchPartGrid();
        }
        else
        {
          this.viewOnlyForSupplier="-";
          this.searchPartGrid();
          //this.isloaderArchiveSearchPart=false; // no need to wait archive data
          this.loadArchiveSearchPartGrid();
        }

        
      })
    }
    else
    {
      this.viewOnlyForSupplier="-";
      this.userIsFord=true;
      this.searchPartGrid();
      this.loadArchiveSearchPartGrid();
    }

  }

  async searchPartGrid()
  {

   
    /* this.transactionService.getSearchPartDetails(this.transactionService.getAllRegionCode(this.selected_region_from_list),this.apprv_stat_grid_inp,this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_status_grid_inp,this.part_sup_code,this.part_plant_code,this.part_prg_code,this.part_sp_cntry,this.part_trad_cmp,this.userid,this.viewOnlyForSupplier).subscribe(griddetls=>{
      
      var temporaryObj:searchpartgrid[]=[];

      this.searchpartgridfromapi=JSON.parse(JSON.stringify(griddetls));

      console.log("searchpartgridfromapi",this.searchpartgridfromapi);
      var partNotMatProposlaStat=[];
      if(this.searchpartgridfromapi!=null)
      {
        for (let gd=0;gd<this.searchpartgridfromapi.length;gd++)
        {
          var apprstat="";
          console.log("outputtesting",this.searchpartgridfromapi[gd]);
          if(this.searchpartgridfromapi[gd].proposal_status_curr!=null)
          {
            var apprstatIndex=this.apprvr_status_list.findIndex(p=>Number(p.cd_propsl)===Number(this.searchpartgridfromapi[gd].proposal_status_curr))

            if(apprstatIndex == -1)
            {
              partNotMatProposlaStat.push(this.searchpartgridfromapi[gd]);
              continue;
            }
            else
            {
              apprstat=this.apprvr_status_list[apprstatIndex].propsl_stat;
            }
          }
          else
          {
              // @ts-ignore
              apprstat=null;
          }

          let partType=null;
          if(this.searchpartgridfromapi[gd].form1271Requested!=null)
          {
            if(this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='g')
            {
              partType='Normal';
            }
            else if(this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='m')
            {
              partType='Migration'
            }
            else if(this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='r' || this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='t')
            {
              partType='Obsolete'
            }
          }

    
          var disableRowChkBox:boolean=true;
          if(this.searchpartgridfromapi[gd].partstatus != null && this.searchpartgridfromapi[gd].partstatus.trim() != "" && 
          (this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "new part" || this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "unsubmitted" || this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "recalled" || this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "rejected"))
          {
            disableRowChkBox=false;
          }

          // @ts-ignore
            // @ts-ignore
            // @ts-ignore
            temporaryObj.push(
            {
              "bompartmasterid": this.searchpartgridfromapi[gd].bompartmasterid,
             "id_proposal": this.searchpartgridfromapi[gd].id_proposal,
            // "propsl_type":this.searchpartgridfromapi[gd].propsl_type,
             "partnumber":this.searchpartgridfromapi[gd].partprefix+'-'+this.searchpartgridfromapi[gd].partbase+'-'+this.searchpartgridfromapi[gd].partsuffix,
              "plantgsdbcode": this.searchpartgridfromapi[gd].plantgsdbcode,
              "partdesc": this.searchpartgridfromapi[gd].partdesc,
              "partstatus": this.searchpartgridfromapi[gd].partstatus.trim(),
              "supplierid": this.searchpartgridfromapi[gd].suppliergsdbcode+' - '+this.searchpartgridfromapi[gd].supfullname,
              "partnumbercommonized": this.searchpartgridfromapi[gd].partnumbercommonized,
              "proposal_status_desc": apprstat,
              "tradingcogsdbcode": this.searchpartgridfromapi[gd].tradingcogsdbcode,
              "supfullname": this.searchpartgridfromapi[gd].supfullname,
              "supcountrycode": this.searchpartgridfromapi[gd].supcountrycode,
              "form1271Requested": this.searchpartgridfromapi[gd].form1271Requested,
              "partType":partType,
              "partIntroDate": this.searchpartgridfromapi[gd].partIntroDate,
              "offsitesuppid":this.searchpartgridfromapi[gd].offsitesuppid,
              "cntrDesgnSrcId": this.searchpartgridfromapi[gd].cntrDesgnSrcId,
              "dis": this.searchpartgridfromapi[gd].dis,
              "disableCheckBox":disableRowChkBox,
              "supplierGSDBcode":this.searchpartgridfromapi[gd].suppliergsdbcode,
              "programname":this.searchpartgridfromapi[gd].programname,
              "pfeppartstatus":this.searchpartgridfromapi[gd].pfeppartstatus
              
            }
          )


        
        }
      }
        this.searchpartgrid=JSON.parse(JSON.stringify(temporaryObj));
        console.log("searchpartgrid",this.searchpartgrid);
        console.log("Parts that doesnt match the proposal status from list",partNotMatProposlaStat);
        //sessionStorage.setItem("partsfromcopycntrdialog",JSON.stringify(this.searchpartgrid))
        //alert("partsfromcopycntrdialog"+JSON.stringify(this.searchpropgrid))
        this.isloadersearchprop=false;


        if(this.searchpartgrid.length==0)
        {
          this.search_result="No NGGPP Parts Found"
        }

    this.cols=[
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
     // {field:'propsl_type',header:'Proposal Type'},
     {field:'partType', header:'Part Type'},

      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      { field: 'partdesc', header:'Part Description'},
      { field: 'pfeppartstatus', header:'Part Status'},
      { field: 'proposal_status_desc', header:'Approval Status'},
      { field: 'supplierid', header:'Supplier GSDB Code'},
      {field:'cntrDesgnSrcId', header:'Design Source GSDB Code'},
     // { field: 'supfullname', header:'Supplier Name'},
      { field: 'supcountrycode', header:'Supplier Country'},
      {field:'tradingcogsdbcode', header:'Trading Company'},
      {field:'partIntroDate', header:'Part Intro Date'},
      {field:'offsitesuppid', header:'Offsite Supplier ID'},
     
      {field:'dis', header:'DIS'},
      
    ];
    },
    (error) => {                              //Error callback
      console.error('Error in Search Part API call - getSearchPartDetails',error)
      this.errorLoadingData = "Error Occured in loading Search Part, Please Try Again";
      this.isloadersearchprop=false;
  
    }
    )*/
    var i=0;
    var loopCompleted=false;
    this.searchpartgridfromapi=[];
    let partNotMatProposalStat=[];
    

    do
    {
      if(i>=0)
      {
        var temporaryObjfromapi:any;
        var temporaryObjgrid:searchpartgrid[]=[];
  
       let result:any= await this.transactionService.getSearchPartDetails(this.transactionService.getAllRegionCode(this.selected_region_from_list),this.apprv_stat_grid_inp,this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_status_grid_inp,this.part_sup_code,this.part_plant_code,this.part_prg_code,this.part_sp_cntry,this.part_trad_cmp,this.userid,this.viewOnlyForSupplier,i);
      
       var check:GPP_search_grid_API[]=[];
       temporaryObjfromapi = result.map(o => Object.assign(o))
       this.searchpartgridfromapi.push(...temporaryObjfromapi);
     
 
       console.log("searchpartgridfromapi",this.searchpartgridfromapi);
       var partNotMatProposlaStat=[];
       if(this.searchpartgridfromapi!=null)
       {

        if(this.searchpartgridfromapi[this.searchpartgridfromapi.length-1])
        {
          i=this.searchpartgridfromapi[this.searchpartgridfromapi.length-1].rowno;
        }

         for (let gd=0;gd<this.searchpartgridfromapi.length;gd++)
         {
           var apprstat="";
           console.log("called search part");
           if(this.searchpartgridfromapi[gd].proposal_status_curr!=null)
           {
             var apprstatIndex=this.apprvr_status_list.findIndex(p=>Number(p.cd_propsl)===Number(this.searchpartgridfromapi[gd].proposal_status_curr))
 
             if(apprstatIndex == -1)
             {
               partNotMatProposlaStat.push(this.searchpartgridfromapi[gd]);
               partNotMatProposalStat.push(partNotMatProposlaStat);
               continue;
             }
             else
             {
               apprstat=this.apprvr_status_list[apprstatIndex].propsl_stat;
             }
           }
           else
           {
               // @ts-ignore
               apprstat=null;
           }
 
           let partType=null;
           if(this.searchpartgridfromapi[gd].form1271Requested!=null)
           {
             if(this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='g')
             {
               partType='Normal';
             }
             else if(this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='m')
             {
               partType='Migration'
             }
             else if(this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='r' || this.searchpartgridfromapi[gd].form1271Requested.toLowerCase()=='t')
             {
               partType='Obsolete'
             }
           }
 
     
           var disableRowChkBox:boolean=true;
           if(this.searchpartgridfromapi[gd].partstatus != null && this.searchpartgridfromapi[gd].partstatus.trim() != "" && 
           (this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "new part" || this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "unsubmitted" || this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "recalled" || this.searchpartgridfromapi[gd].partstatus.trim().toLowerCase() == "rejected"))
           {
             disableRowChkBox=false;
           }
 
           // @ts-ignore
             // @ts-ignore
             // @ts-ignore
             temporaryObjgrid.push(
             {
               "bompartmasterid": this.searchpartgridfromapi[gd].bompartmasterid,
              "id_proposal": this.searchpartgridfromapi[gd].id_proposal,
              "partnumber":this.searchpartgridfromapi[gd].partprefix+'-'+this.searchpartgridfromapi[gd].partbase+'-'+this.searchpartgridfromapi[gd].partsuffix,
               "plantgsdbcode": this.searchpartgridfromapi[gd].plantgsdbcode,
               "partdesc": this.searchpartgridfromapi[gd].partdesc,
               "partstatus": this.searchpartgridfromapi[gd].partstatus.trim(),
               "supplierid": this.searchpartgridfromapi[gd].suppliergsdbcode+' - '+this.searchpartgridfromapi[gd].supfullname,
               "partnumbercommonized": this.searchpartgridfromapi[gd].partnumbercommonized,
               "proposal_status_desc": apprstat,
               "tradingcogsdbcode": this.searchpartgridfromapi[gd].tradingcogsdbcode,
               "supfullname": this.searchpartgridfromapi[gd].supfullname,
               "supcountrycode": this.searchpartgridfromapi[gd].supcountrycode,
               "form1271Requested": this.searchpartgridfromapi[gd].form1271Requested,
               "partType":partType,
               "partIntroDate": this.searchpartgridfromapi[gd].partIntroDate,
               "offsitesuppid":this.searchpartgridfromapi[gd].offsitesuppid,
               "cntrDesgnSrcId": this.searchpartgridfromapi[gd].cntrDesgnSrcId,
               "dis": this.searchpartgridfromapi[gd].dis,
               "disableCheckBox":disableRowChkBox,
               "supplierGSDBcode":this.searchpartgridfromapi[gd].suppliergsdbcode,
               "programname":this.searchpartgridfromapi[gd].programname,
               "pfeppartstatus":this.searchpartgridfromapi[gd].pfeppartstatus
               
             }
           )
 
 
         
         }
       }
        
      }
      else
      {
        i=-1;
        loopCompleted=true;
      }
    }while(i>=0 && this.searchpartgridfromapi.length==1000)

    this.searchpartgrid=JSON.parse(JSON.stringify(temporaryObjgrid));
      console.log("searchpartgrid",this.searchpartgrid);
      console.log("Parts that doesnt match the proposal status from list",partNotMatProposalStat);
      //sessionStorage.setItem("partsfromcopycntrdialog",JSON.stringify(this.searchpartgrid))
      //alert("partsfromcopycntrdialog"+JSON.stringify(this.searchpropgrid))
    


      if(this.searchpartgrid.length==0)
      {
        this.search_result="No NGGPP Parts Found";
      }

    this.isloadersearchprop=false;

    this.cols=[
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
      // {field:'propsl_type',header:'Proposal Type'},
      {field:'partType', header:'Part Type'},

      { field: 'partnumbercommonized', header:'Simplified Part Number'},
      { field: 'partdesc', header:'Part Description'},
      { field: 'pfeppartstatus', header:'Part Status'},
      { field: 'proposal_status_desc', header:'Approval Status'},
      { field: 'supplierid', header:'Supplier GSDB Code'},
      {field:'cntrDesgnSrcId', header:'Design Source GSDB Code'},
      // { field: 'supfullname', header:'Supplier Name'},
      { field: 'supcountrycode', header:'Supplier Country'},
      {field:'tradingcogsdbcode', header:'Trading Company'},
      {field:'partIntroDate', header:'Part Intro Date'},
      {field:'offsitesuppid', header:'Offsite Supplier ID'},
      
      {field:'dis', header:'DIS'},
      
    ];
  

     
 
  }

 async loadArchiveSearchPartGrid()
  {
    let cos_in_grid="all";
    let approvalStatusForArchive=""; // Archive Search Part APi Requires Approval status not status code
    let suppliercode="" // If user is supplier send their access specific supplier codes; for ford we can send all or selected codes

    if(this.selected_approval_status.length==0)
    {
      approvalStatusForArchive="all"
    }
    else
    {
      for(var i=0; i<this.selected_approval_status.length;i++)
      {
        approvalStatusForArchive+=this.selected_approval_status[i].propsl_stat;
  
        if(i!=this.selected_approval_status.length-1)
        {
          approvalStatusForArchive+=",";
        }
      }
    }

    if(this.userIsFord!=null && this.userIsFord==false)
    {
      if(this.part_sup_code.toLowerCase()=="all")
      {
        let regionCode=this.transactionService.getregioncode(this.current_region);
      
        var viewOnlyRoleIndex=this.userAccessGrid.findIndex(e=>e.cd_region.toLowerCase()==regionCode.toLowerCase() && e.role_id==99);
        var supplierRoleIndex=this.userAccessGrid.findIndex(e=>e.cd_region.toLowerCase()==regionCode.toLowerCase() && e.role_id==1);
        
        if(viewOnlyRoleIndex!=-1)
        {
          suppliercode+=this.userAccessGrid[viewOnlyRoleIndex].cd_supplier;
          
          if(supplierRoleIndex!=-1)
          {
            suppliercode+=","
            suppliercode+=this.userAccessGrid[supplierRoleIndex].cd_supplier;
          }
        }
        else
        {
          suppliercode=this.userAccessGrid[supplierRoleIndex].cd_supplier;
        }
      }
      else
      {
        suppliercode=this.part_sup_code;
      }
      
    }
    else
    {
      suppliercode=this.part_sup_code;
    }

   
   //var data:searchProposalGridFromAPI[]=[]
   var i=0;
   var loopCompleted=false;

  
   do
   {
    if(i>=0)
    {
      var tempsearchpartgridfromapi:any;
      var tempsearchpartgrid:GPP_search_grid_API[];

      let result:any= await this.transactionService.get_GPP_SearchPartDetails(this.userid,this.transactionService.getregioncode(this.current_region),this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_plant_code,this.part_prg_code,suppliercode,this.part_sp_cntry,this.part_trad_cmp,approvalStatusForArchive,cos_in_grid,"all","all",i)
          
      var check:GPP_search_grid_API[]=[];
      tempsearchpartgridfromapi = result.map(o => Object.assign(o))
      this.archiveSearchPartFromAPI.push(...tempsearchpartgridfromapi);
    

      if(tempsearchpartgridfromapi!=null) //&& tempsearchpartgridfromapi.keys.length!=0)
      {
        if(tempsearchpartgridfromapi[tempsearchpartgridfromapi.length-1])
        {
          i=tempsearchpartgridfromapi[tempsearchpartgridfromapi.length-1].seqno;
          loopCompleted=false;
  
          
          const temporaryObj:GPP_Search_Prt_grid[]=[];
  
  
          console.log('tempsearchpartgridfromapi',tempsearchpartgridfromapi);
          if(tempsearchpartgridfromapi!=null)
          {
            for (let gd=0;gd<tempsearchpartgridfromapi.length;gd++)
            {
              //console.log('outputtesting',this.searchpartgridfromapi[gd]);
  
  
  
              let part_id = '';
              if(tempsearchpartgridfromapi[gd].part_prefix!=null && tempsearchpartgridfromapi[gd].part_prefix.trim() != '')
              {
                part_id = tempsearchpartgridfromapi[gd].part_prefix+'-';
              }
              else
              {
                part_id = ' '+'-';
              }
              if(tempsearchpartgridfromapi[gd].part_base!=null && tempsearchpartgridfromapi[gd].part_base.trim() != '')
              {
                part_id += tempsearchpartgridfromapi[gd].part_base+'-';
              }
              else
              {
                part_id += ' '+'-';
              }
              if(tempsearchpartgridfromapi[gd].part_suffix!=null && tempsearchpartgridfromapi[gd].part_suffix.trim() != '')
              {
                part_id += tempsearchpartgridfromapi[gd].part_suffix;
              }
              else
              {
                part_id += ' ';
              }
              
              
              var cntrbaseAndSuffix=tempsearchpartgridfromapi[gd].container_base;
              if(tempsearchpartgridfromapi[gd].container_suffix!=null && tempsearchpartgridfromapi[gd].container_suffix.trim()!="")
              {
                cntrbaseAndSuffix+="-";
                cntrbaseAndSuffix+=tempsearchpartgridfromapi[gd].container_suffix;
              }
                
                
  
              temporaryObj.push(
                {
                  
                "form_id": tempsearchpartgridfromapi[gd].form_id,
                "partnumber":part_id,
                  "plantgsdbcode": tempsearchpartgridfromapi[gd].plant_gsdb_code,
                  "partdesc": tempsearchpartgridfromapi[gd].part_description,
                  "partnumbercommonized": tempsearchpartgridfromapi[gd].simplified_part_number,
                  "proposal_status_desc": tempsearchpartgridfromapi[gd].approval_status,
                  "tradingcogsdbcode": tempsearchpartgridfromapi[gd].trading_company_gsdb,
                  "supcountrycode": tempsearchpartgridfromapi[gd].supplier_country_code_sup,
                  "partIntroDate": tempsearchpartgridfromapi[gd].part_intro_date,
                  "supplierGSDBcode":tempsearchpartgridfromapi[gd].supplier_gsdb,
                  "cntrbaseandsuffix":cntrbaseAndSuffix
                  
                }
              )
            }
          }
          tempsearchpartgrid=JSON.parse(JSON.stringify(temporaryObj));
          console.log("archive temp searchpartgrid",tempsearchpartgrid);
  
          //Object.assign(this.searchpartgrid,tempsearchpartgrid)
          this.archiveSearchPartGrid.push(...tempsearchpartgrid);
        }
 
      }
    }
    else
    {
      i=-1;
      loopCompleted=true;
    }
   }while(i>=0 && tempsearchpartgridfromapi.length==1000)

    /*if(loopCompleted==true)
    {*/
    this.isloaderArchiveSearchPart=false;
            
      
    if(this.archiveSearchPartGrid.length==0)
    {
      this.archive_search_result="No Archive Parts Found"
    }

      this.cols2=
      [
        { field: 'plantgsdbcode', header:'Plant GSDB Code'},
        { field: 'partnumber', header:'Part Number'},
        { field: 'partnumbercommonized', header:'Simplified Part Number'},
        { field: 'partdesc', header:'Part Description'}, 
        { field: 'proposal_status_desc', header:'Approval Status'},
        { field: 'supplierGSDBcode', header:'Supplier GSDB Code'}, 
        { field: 'supcountrycode', header:'Supplier Country'},
        {field:'tradingcogsdbcode', header:'Trading Company'},
        {field:'partIntroDate', header:'Part Intro Date'},
      
      ];
   
/*
    this.transactionService.get_GPP_SearchPartDetails(this.userid,this.transactionService.getregioncode(this.current_region),this.part_prefix,this.part_base,this.part_suffix,this.part_desc,this.part_plant_code,this.part_prg_code,suppliercode,this.part_sp_cntry,this.part_trad_cmp,approvalStatusForArchive,cos_in_grid,"all","all").subscribe(archivesearchgrid=>
    {
  
      var temporaryObj2:GPP_Search_Prt_grid[]=[];

      this.archiveSearchPartFromAPI=JSON.parse(JSON.stringify(archivesearchgrid));

      console.log("archiveSearchPartFromAPI",this.archiveSearchPartFromAPI);
      if(this.archiveSearchPartFromAPI!=null)
      {
        for (var gd=0;gd<this.archiveSearchPartFromAPI.length;gd++)
        {
          

          var part_id = "";
          if(this.archiveSearchPartFromAPI[gd].part_prefix!=null && this.archiveSearchPartFromAPI[gd].part_prefix.trim() != "")
          {
            part_id = this.archiveSearchPartFromAPI[gd].part_prefix+'-';
          }
          else
          {
            part_id = " "+"-";
          }
          if(this.archiveSearchPartFromAPI[gd].part_base!=null && this.archiveSearchPartFromAPI[gd].part_base.trim() != "")
          {
            part_id += this.archiveSearchPartFromAPI[gd].part_base+'-';
          }
          else
          {
            part_id += " "+"-";
          }
          if(this.archiveSearchPartFromAPI[gd].part_suffix!=null && this.archiveSearchPartFromAPI[gd].part_suffix.trim() != "")
          {
            part_id += this.archiveSearchPartFromAPI[gd].part_suffix;
          }
          else
          {
            part_id += " ";
          }

          var cntrBaseAndSuffix=this.archiveSearchPartFromAPI[gd].container_base;
          if(this.archiveSearchPartFromAPI[gd].container_suffix!=null && this.archiveSearchPartFromAPI[gd].container_suffix.trim()!="")
          {
            cntrBaseAndSuffix+="-";
            cntrBaseAndSuffix+=this.archiveSearchPartFromAPI[gd].container_suffix;
          }
     
          temporaryObj2.push
          (
            {        
              "form_id": this.archiveSearchPartFromAPI[gd].form_id,
              "partnumber":part_id,
              "plantgsdbcode": this.archiveSearchPartFromAPI[gd].plant_gsdb_code,
              "partdesc": this.archiveSearchPartFromAPI[gd].part_description,
              "partnumbercommonized": this.archiveSearchPartFromAPI[gd].simplified_part_number,
              "proposal_status_desc": this.archiveSearchPartFromAPI[gd].approval_status,
              "tradingcogsdbcode": this.archiveSearchPartFromAPI[gd].trading_company_gsdb,
              "supcountrycode": this.archiveSearchPartFromAPI[gd].supplier_country_code_sup,
              "partIntroDate": this.archiveSearchPartFromAPI[gd].part_intro_date,
              "supplierGSDBcode":this.archiveSearchPartFromAPI[gd].supplier_gsdb,
              "cntrbaseandsuffix":cntrBaseAndSuffix
                
            }
          )
        }
      }
      
      this.archiveSearchPartGrid=JSON.parse(JSON.stringify(temporaryObj2));
      console.log("archiveSearchPartGrid",this.archiveSearchPartGrid);
      this.isloaderArchiveSearchPart=false;
      

      if(this.archiveSearchPartGrid.length==0)
      {
        this.archive_search_result="No Archive Parts Found"
      }

        this.cols2=
        [
          { field: 'plantgsdbcode', header:'Plant GSDB Code'},
          { field: 'partnumber', header:'Part Number'},
          { field: 'partnumbercommonized', header:'Simplified Part Number'},
          { field: 'partdesc', header:'Part Description'}, 
          { field: 'proposal_status_desc', header:'Approval Status'},
          { field: 'supplierGSDBcode', header:'Supplier GSDB Code'}, 
          { field: 'supcountrycode', header:'Supplier Country'},
          {field:'tradingcogsdbcode', header:'Trading Company'},
          {field:'partIntroDate', header:'Part Intro Date'},
        
        ];
    },
    (error) => {                              //Error callback
        console.error('Error in Archive Search Part API call - get_GPP_SearchPartDetails',error)
        this.errorLoadingArchiveData = "Error Occured in loading Archive Data, Please Try Again";
        this.isloaderArchiveSearchPart=false;
    
       }
    )*/
  }

 

  clearFilters()
  {
    this.selectedpartprefix="";
    this.selectedpartbase="";
    this.selectedpartsuffix="";
    this.selectedpartdesc="";
    
    this.errorLoadingData = "";
    this.errorLoadingArchiveData="";

    this.loadProposalText="";
    
    this.selected_suppliercountry=[];
    this.suppliercountry='';

    this.selected_plant_codes_list=[];
    this.selected_plant_code={
      plant_name: "",
      cd_plant: "",
      plant_type_name: "",
      plantGSDBCode: "",
    
      
    
    }
    this.plantcode="";
    this.selected_programs_list=[];
    this.programcodes='';
    this.plantcodeselected=false;
    this.selectedtrdcmpy=[];
    this.trdcmpy='';

    this.search_result="";
    this.archive_search_result="";
   
  

      this.selected_approval_status=[];
      this.approval_status='';


    this.selectedstatuslist=[];
    this.part_status='';



    this.selected_approval_status=[];
    this.approval_status='';
    this.selected_supplier={supcode:'',fullname:'',supplierid:''};
    this.suupliercode=""
    this.supplierfullname="";
    this.supplierEntered="";
    
    if(this.regionlist.length!=0)
    {
      if(this.regionlist.filter(reg=>reg.value==this.current_region))
      {
        this.selected_region_from_list.push({label:this.current_region, value: this.current_region});
      }
    }

    
    this.regionchanged();

    this.searchpartgrid=[];
    this.archiveSearchPartGrid=[];
 
  }

  loadArchiveProposal(rowData)
  {

    console.log(rowData);
    if(rowData.form_id != null)
    {
      console.log(rowData.form_id);
      if(this.prevFormId != null && this.prevFormId.trim() != "" && this.prevFormId == rowData.form_id)
      {
        console.log("else formid")
        this.enable=true;
      }
      else
      {

        this.loadProposalText=""
        this.formId=""
        this.formlink="";
        this.historylink="";
        console.log("Plantcode", rowData.plantgsdbcode);
        console.log("Form ID",rowData.form_id);
        this.disableDownloadPdfButton=true;
        this.disableDownloadHistoryButton=true;
        this.isLoaderLoadFormArchive=true;
        this.isLoaderLoadHistoryArchive=true
        this.loadFormPDFerrorText=""
        this.loadHistoryPDFerrorText=""

        if(rowData.plantgsdbcode != null && rowData.plantgsdbcode.trim() != "" && rowData.form_id != null && rowData.form_id.trim() != "")
        {
          let form_link = this.base_url_get_archieve_report+this.base_path_archive_report+rowData.plantgsdbcode+'/'+'Form1271_'+rowData.form_id+'.pdf';
          let history_link = this.base_url_get_archieve_report+this.base_path_archive_report+rowData.plantgsdbcode+'/'+'History_'+rowData.form_id+'.pdf';

          this.transactionService.getPdfFile(form_link).subscribe((res) => {
            this.formlink = URL.createObjectURL(res);
            console.log("Res", res);
          }, error => {
            this.loadFormPdferror();
            this.loadHistoryPdferror();
          });
          this.transactionService.getPdfFile(history_link).subscribe((res) => {
            this.historylink = URL.createObjectURL(res);
          }, error => {
            this.loadFormPdferror();
            this.loadHistoryPdferror();
          });

          this.formId=rowData.form_id
          this.prevFormId=rowData.form_id;  
          console.log("Form Link", this.formlink);
          console.log("History Link",this.historylink);
          this.enable=true;

        }

        else
        {
          this.isLoaderLoadFormArchive=false;
          this.isLoaderLoadHistoryArchive=false;
          console.log("Either plant code or form id is not correct")
          if(rowData.partnumbercommonized != null && rowData.partnumbercommonized.trim() != "")
          {
            this.loadProposalText="Error Occured, cannot load Form 1271 for Part Number :"+"rowData.partnumbercommonized";
          }
          else
          {
            this.loadProposalText="Error Occured, cannot load Form 1271";
          }
        
        }
      }
    }
    else
    {
      this.enable=true;
      this.formId=""
      this.formlink="";
      this.historylink="";
      this.loadProposalText="Error occured, Incoorect Form Id";
      console.log("Form Id Null");
    }
    
  }

  loadFormPdfComplete()
  {
    this.disableDownloadPdfButton=false;
    this.isLoaderLoadFormArchive=false;
  }
  loadFormPdferror()
  {
    this.loadFormPDFerrorText="Form missing or Error occured";
    this.isLoaderLoadFormArchive=false;
  }
  
  loadHistoryComplete()
  {
    this.disableDownloadHistoryButton=false;
    this.isLoaderLoadHistoryArchive=false;
  }
  loadHistoryPdferror()
  {
    this.loadHistoryPDFerrorText="Revision History missing or Error occured ";
    this.isLoaderLoadHistoryArchive=false;
  }

  downloadArchivePdf()
  {
    console.log("downloadArchivePdf fn ");
    this.downloadPdfText=""

    var links=[];
    if(!this.disableDownloadPdfButton)
    {
      links.push( {
        link_name: this.formlink,
         doc_name:'Form1271_'+this.formId+'.pdf'})
    }
    else
    {
      this.downloadPdfText="No Form 1271 to download."
    }
    if(!this.disableDownloadHistoryButton)
    {
    links.push({
        link_name: this.historylink,
       doc_name: 'History_'+this.formId+'.pdf'})
    }
    else
    {
      this.downloadPdfText+="No Revision History to download."
    }


       if(links.length != 0)
       {
        links.forEach(link=>{
          this.http.get(link.link_name,{ responseType:'blob'}).subscribe(data=>{
          const blob = new Blob([data],{ type : 'application/pdf'});
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href=url;
          a.download=link.doc_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          
          })
          })
       }
       


  }

  sendSelectedPartsBulkEmailButton()
  {
    console.log('sendSelectedPartsBulkEmailButton - selected parts list for bulk email',this.selectedPartsForBulkEmail);
    this.enableSendEmailPopUp=true;
    this.bulkEmailSubject='ATTENTION : Action Required'
  }

  selectedPartDetailsForBulkemailPostInit()
  {
    this.selectedPartsForBulkEmailPost={

      partDetls:[
        {
          partNumberCommonized:'',
          plantCode:'',
          programName:'',
          supCode:''
        }
      ],
      subject:'',
      emailBody:'',
      copyMe:'',
      user:''


    }
  }
  sendBulkEmailPostPopUpButton()
  {
    console.log('sendBulkEmailPostPopUpButton - Selected parts list for bulk email',this.selectedPartsForBulkEmail);
    console.log('Bulk email subject', this.bulkEmailSubject)
    console.log('Bulk email Body',this.bulkEmailBody);
    console.log('Bulk email Copy me Check box',this.copyMeOnBulkEmail);

    this.selectedPartDetailsForBulkemailPostInit();

    if(this.selectedPartsForBulkEmail != null && this.selectedPartsForBulkEmail.length != 0)
    {
      this.selectedPartsForBulkEmailPost.partDetls=[];
      for(let i=0; i<this.selectedPartsForBulkEmail.length; i++)
      {
        this.selectedPartsForBulkEmailPost.partDetls.push(
            {
              partNumberCommonized:this.selectedPartsForBulkEmail[i].partnumbercommonized,
              plantCode:this.selectedPartsForBulkEmail[i].plantgsdbcode,
              programName:this.selectedPartsForBulkEmail[i].programname,
              supCode:this.selectedPartsForBulkEmail[i].supplierGSDBcode
            }
        )
      }

      this.selectedPartsForBulkEmailPost.subject=this.bulkEmailSubject;
      this.selectedPartsForBulkEmailPost.emailBody=this.bulkEmailBody;
      let copyMe='N'
      if(this.copyMeOnBulkEmail)
      {
        copyMe='Y'
      }
      this.selectedPartsForBulkEmailPost.copyMe=copyMe;
      this.selectedPartsForBulkEmailPost.user=this.userid

      console.log('Selected parts for bulk email post before api call',this.selectedPartsForBulkEmailPost)

      this.transactionService.searchPartsBulkEmailForPost(this.selectedPartsForBulkEmailPost).subscribe(searchPartsBulkEmailRes=>{
        console.log('Selected parts Bulk Email For Post Response',searchPartsBulkEmailRes)
        if(searchPartsBulkEmailRes != null && searchPartsBulkEmailRes.toLowerCase() == 'success')
        {
          this.enableSendEmailPopUp=false;
          this.bulkEmailBody='';
          this.bulkEmailSubject='';
          this.copyMeOnBulkEmail=false;
          this.selectedPartsForBulkEmail=[];
          this.selectedPartDetailsForBulkemailPostInit();

          this.messageService.add({severity:'success', summary: 'success', detail: 'Email sent successfully'});

        }
        else
        {
          this.messageService.add({severity:'error', summary: 'Error', detail: 'Email not sent,Please Try Again'})
        }
      })
    }




  }
  closeBulEmailPopUp()
  {
    this.enableSendEmailPopUp=false;
    this.bulkEmailBody='';
    this.bulkEmailSubject='';
    this.copyMeOnBulkEmail=false;
  }

  disableEmailPopUp():boolean
  {
    if(this.bulkEmailSubject == null || this.bulkEmailSubject.trim() == '' || this.bulkEmailBody == null || this.bulkEmailBody.trim() == '')
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  deSelectSelectedParts()
  {
    this.selectedPartsForBulkEmail=[];
  }

  viewSelectedParts()
  {
    console.log('View Selected Parts', this.selectedPartsForBulkEmail);

    this.enableViewSelectedPartsPopup=true;

    this.cols_view=[
      { field: 'plantgsdbcode', header:'Plant GSDB Code'},
      {field:'partType', header:'Part Type'},
      { field: 'partnumbercommonized', header:'Part Number'},
      { field: 'partdesc', header:'Part Description'},
      { field: 'partstatus', header:'Part Status'},
      { field: 'proposal_status_desc', header:'Proposal Status'},
      { field: 'supplierid', header:'Supplier GSDB Code'},
      {field:'partIntroDate', header:'Part Intro Date'},
      {field:'offsitesuppid', header:'Offsite Supplier ID'},
    ]

  }

  loadproposal(rowfromgrid:searchProposalGrid)
  {
    console.log(rowfromgrid);

    this.selectedpartnodetails.bompartmasterid=rowfromgrid.bompartmasterid;
    this.selectedpartnodetails.simplifiedpartno=rowfromgrid.partnumbercommonized;
    this.selectedpartnodetails.proposalid=rowfromgrid.id_proposal;
    this.selectedpartnodetails.plantgsdbcode=rowfromgrid.plantgsdbcode;
    this.selectedpartnodetails.proposallastsaved=4;
    this.selectedpartnodetails.propsl_type=rowfromgrid.propsl_type;

    if(this.selectedpartnodetails.propsl_type==null || this.selectedpartnodetails.propsl_type.toLowerCase()=='normal' || this.selectedpartnodetails.propsl_type.toLowerCase()=='migration')
    {
      const ind=this.statusList.findIndex(p=>p.status==rowfromgrid.partstatus);
      this.selectedpartnodetails.part_status=String(this.statusList[ind].status);
      this.selectedpartnodetails.imp_part_status=rowfromgrid.imp_ngpp_part_status
    }
    else
    {
      const ind=this.statusList.findIndex(p=>p.status==rowfromgrid.imp_ngpp_part_status);
      if(ind!=-1)
      {
        this.selectedpartnodetails.imp_part_status=String(this.statusList[ind].status);
      }
      else
      {
        this.selectedpartnodetails.imp_part_status=null;
      }
      this.selectedpartnodetails.part_status=rowfromgrid.partstatus
    }
    this.selectedpartnodetails.supplier_code=rowfromgrid.supplierid.split('-')[0];
    this.selectedpartnodetails.proposallastsaved_ee=1;
    this.selectedpartnodetails.currentview_ee=false;
    this.selectedpartnodetails.is_ee_proposal=false;
    this.selectedpartnodetails.from_draft=false;
    this.selectedpartnodetails.propsl_type=rowfromgrid.propsl_type;
    this.store_primary_part();
  }

  store_primary_part()
  {
    const regcode=this.region_code;
    // this.transactionService.getregioncode(this.selected_region_from_list);

    if(regcode=='NA')
    {
      sessionStorage.setItem('napackpropart', JSON.stringify(this.selectedpartnodetails));
    }

    if(regcode=='EU')
    {
      sessionStorage.setItem('eupackpropart', JSON.stringify(this.selectedpartnodetails));
    }

    if(regcode=='AP')
    {
      sessionStorage.setItem('appackpropart', JSON.stringify(this.selectedpartnodetails));
    }

    if(regcode=='SA')
    {
      sessionStorage.setItem('sapackpropart', JSON.stringify(this.selectedpartnodetails));
    }


    this.enabledetails_search=false;

    this.view_proposal=true;

  }

  view_revision_history_search()
  {


    this.enabledetails_search=true;

    this.revision_obj_search=[];
    this.transactionService.getRevisionHistoryByProposalId(this.selectedpartnodetails.proposalid).subscribe(revHistArray=>{

      this.revHistFromServiceAPI=[];
      this.revHistFromServiceAPI=revHistArray;
      this.revision_obj_search=[];
      console.log('getRevisionHistoryByProposalId API o/p ',this.revHistFromServiceAPI);



      if(this.revHistFromServiceAPI != null && this.revHistFromServiceAPI.length != 0)
      {
        for(let i=0; i<this.revHistFromServiceAPI.length; i++)
        {
          let rev_htm_str='';
         /* var approvalStage="";

          if(this.approvalStatusList != null && this.approvalStatusList.length !=0 && this.revHistFromServiceAPI[i].proposalstatuscurr != null)
          {
          var approvalStatusIndex=this.approvalStatusList.findIndex(ele=>ele.cd_propsl == this.revHistFromServiceAPI[i].proposalstatuscurr)
          if(approvalStatusIndex != -1)
          {
            approvalStage=this.approvalStatusList[approvalStatusIndex].propsl_stat;
          }
          }*/


          if(this.revHistFromServiceAPI[i].evnttime != null && this.revHistFromServiceAPI[i].usrname != null)
          {
            rev_htm_str='<b>'+this.revHistFromServiceAPI[i].evnttime+';'+this.revHistFromServiceAPI[i].usrname+'('+this.revHistFromServiceAPI[i].email+')'+'</b>'+'<br>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn;
          }
          else
          {
            rev_htm_str='<b>'+this.revHistFromServiceAPI[i].proposalstatuscurr+'-'+this.revHistFromServiceAPI[i].apprvractn+'</b>';
          }
          if(this.revHistFromServiceAPI[i].comments != null && this.revHistFromServiceAPI[i].comments != '-')
          {
            rev_htm_str+='<br>'+'Comments-'+this.revHistFromServiceAPI[i].comments;
          }

          this.revision_obj_search.push({rev_obj:this.revHistFromServiceAPI[i],rev_html_string:rev_htm_str})
        }
        console.log('Revision History Display',this.revision_obj_search)
      }

    })

  }


  close_revision_history_search()
  {
   this.enabledetails_search=false;

  }

  exportExcel() {

    var searchPartDataForExcelDownload:searchPartExcelFormat[]=[];

    console.log("search proposal api data",this.searchpartgridfromapi);

    
    this.searchpartgridfromapi.forEach(d=>{

      var propIndex=this.apprvr_status_list.findIndex(p=>Number(p.cd_propsl)==Number(d.proposal_status_curr))
      
   
      var apprstat:string="";
      if(d.proposal_status_curr!=null)
          {
            var apprstatIndex=this.apprvr_status_list.findIndex(p=>Number(p.cd_propsl)===Number(d.proposal_status_curr))

            if(apprstatIndex != -1)
            {
              
              apprstat=this.apprvr_status_list[apprstatIndex].propsl_stat;
            }
            else
            {
              apprstat=null;
            }

          }
          else
          {
            apprstat=null;
          }

      searchPartDataForExcelDownload.push({
        "plantgsdbcode": d.plantgsdbcode,
        "tradingcompany": d.tradingcogsdbcode,
        "partprefix":d.partprefix,
        "partbase":d.partbase,
        "partsuffix":d.partsuffix,
        "partcontrol":"",
        "partdesc":d.partdesc,
        "partstatus":d.partstatus,
        "supgsdbcode":d.suppliergsdbcode,
        "supname":d.supfullname,
        "sup_cntry":d.supcountrycode,
        "cos":d.cntr_ownrshp_strat_code,
        "approvalStatus":apprstat,
        "proposalType":d.propsl_type
      })
    })

    console.log(searchPartDataForExcelDownload)

    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(searchPartDataForExcelDownload,{header:[
          "plantgsdbcode",
          "tradingcompany",
          "partprefix",
          "partbase",
          "partsuffix",
          "partcontrol",
          "partdesc",
          "partstatus",
          "supgsdbcode",
          "supname",
          "sup_cntry",
          "cos",
          "approvalStatus",
          "proposalType"
        ]});

        worksheet.A1.v="Plant GSDB";
        worksheet.B1.v="Trading Company";
        worksheet.C1.v="PartPrefix";
        worksheet.D1.v="PartBase";
        worksheet.E1.v="PartSuffix";
        worksheet.F1.v="PartControl";
        worksheet.G1.v="PartDescription";
        worksheet.H1.v="PartStatus";
        worksheet.I1.v="Supplier GSDB";
        worksheet.J1.v="Supplier Name";
        worksheet.K1.v="Supplier Country Code";
        worksheet.L1.v="Container Ownership";
        worksheet.M1.v="Approval Status";
        worksheet.N1.v="Part Type";
       

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Search Part List");
    });

    /* import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.searchpartgrid);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Proposal List");
    }); */
  }

  exportExcelArchiveGrid()
  {
    var archiveSearchPartDataForExcelDownload:archiveSearchPartExcelFormat[]=[];

    console.log("search part api data",this.archiveSearchPartFromAPI);

    
    this.archiveSearchPartFromAPI.forEach(d=>{

        archiveSearchPartDataForExcelDownload.push({
          "plantgsdbcode": d.plant_gsdb_code,
          "tradingcompany": d.trading_company_gsdb,
          "partprefix":d.part_prefix,
          "partbase":d.part_base,
          "partsuffix":d.part_suffix,
          "partcontrol":d.part_control,
          "partdesc":d.part_description,
        
          "ngpp_part_wt": d.part_weight,
          "supgsdbcode":d.supplier_gsdb,
          
          "sup_cntry":d.supplier_country_code_sup,
          "container_ownership":d.container_ownership,
          "cntr_ret_typ":d.container_e_r,
          "container_base":d.container_base,
          "container_suffix":d.container_suffix,
          "parts_per_cntr": d.parts_per_container,
          "cntr_len":d.container_length,
          "cntr_width":d.container_width,
          "cntr_height":d.container_height,
          "cntr_gross_wt":d.container_gross_weight,
          "comp1base":d.comp_1_base,
          "comp1suffix":d.comp_1_suffix,
          "comp1qty":d.comp_1_qty_per_shipping_unit,
          "comp2base":d.comp_2_base,
          "comp2suffix":d.comp_2_suffix,
          "comp2qty":d.comp_2_qty_per_shipping_unit,
          "shippingunitid":d.container_base+'-'+d.container_suffix,
          "partsPerShippingUnit":d.parts_per_shipping_unit,
          "suLen":d.shipping_unit_length,
          "suWidth":d.shipping_unit_width,
          "suHeight":d.shipping_unit_height,
          "suGrossWt":d.shipping_unit_gross,
          "approvalStatus":d.approval_status,
      }) 
    })

    console.log(archiveSearchPartDataForExcelDownload)

    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(archiveSearchPartDataForExcelDownload,{header:[
          "plantgsdbcode",
          "tradingcompany",
          "partprefix",
          "partbase",
          "partsuffix",
          "partcontrol",
          "partdesc",

          "ngpp_part_wt",
          "supgsdbcode",
          
          "sup_cntry",
          "container_ownership",
          "cntr_ret_typ",
          "container_base",
          "container_suffix",
          "parts_per_cntr",
          "cntr_len",
          "cntr_width",
          "cntr_height",
          "cntr_gross_wt",
          "comp1base",
          "comp1suffix",
          "comp1qty",
          "comp2base",
          "comp2suffix",
          "comp2qty",
          "shippingunitid",
          "partsPerShippingUnit",
          "suLen",
          "suWidth",
          "suHeight",
          "suGrossWt",
          "approvalStatus",
        ]});

        worksheet.A1.v="Plant GSDB";
        worksheet.B1.v="Trading Company";
        worksheet.C1.v="PartPrefix";
        worksheet.D1.v="PartBase";
        worksheet.E1.v="PartSuffix";
        worksheet.F1.v="PartControl";
        worksheet.G1.v="PartDescription";
      
        worksheet.H1.v="PartWeight";
        worksheet.I1.v="Supplier GSDB";
   
        worksheet.J1.v="Supplier Country Code";
        worksheet.K1.v="Container Ownership";
        worksheet.L1.v="Expendable/Returnable";
        worksheet.M1.v="Container Base";
        worksheet.N1.v="Container Suffix";
        worksheet.O1.v="Parts Per Container";
        worksheet.P1.v="Container Length";
        worksheet.Q1.v="Container Width";
        worksheet.R1.v="Container Height";
        worksheet.S1.v="Container Gross Weight";
        worksheet.T1.v="Component1 Base";
        worksheet.U1.v="Component1 Suffix";
        worksheet.V1.v="Component1 QTY";
        worksheet.W1.v="Component2 Base";
        worksheet.X1.v="Component2 Suffix";
        worksheet.Y1.v="Component2 QTY";
        worksheet.Z1.v="Shipping Unit ID";
        worksheet.AA1.v="Parts Per Shipping Unit";
        worksheet.AB1.v="Shipping Unit Length";
        worksheet.AC1.v="Shipping Unit Width";
        worksheet.AD1.v="Shipping Unit Height";
        worksheet.AE1.v="Shipping Unit Gross Weight";
        worksheet.AF1.v="Approval Status";
       

        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Archive Search Part List");
    });

    /* import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.searchpartgrid);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Proposal List");
    }); */
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      import('file-saver').then(FileSaver => {
          const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  go_to_packging_process_home()
  {
    let reg_url:string;
    reg_url=this.transactionService.getregionurlvalue(this.current_region);
    reg_url+='packagingprocessdashboard/packagingprocesshome';
    this.router.navigate([reg_url]);
  }

}
